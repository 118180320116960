.App {
  text-align: center;
}

*{
  font-family: 'Alegreya', serif;
  font-size: 14px;
  font-weight: 500;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.required:after {
  content:"*";
  color:red;
}
.error-text{ 
  color:red;
  font-size: 12px;
  margin-bottom: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.instructions{
  font-size: 14px;
  font-weight: 500;
  color: #3498db;
  margin-bottom: 0.5rem;
}

.form-heading {
  text-align: center;
  margin-top: 15px;
}

.disable-uplaod {
  opacity: 0.5;
  pointer-events: none;
}

.file-loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  color:darkred;
}

.mb {
  margin-bottom: 1rem;
}

.loader {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
